@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.jobs {
    width: 100%;
    height: 70vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(148, 90, 35, 0.274);
  }
  
  .jobs:before {
    content: '';
    position: absolute;
    background: url('../../assets/submenu-bg-jobs.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.jobs .jobs-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.jobs h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;


}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {



.jobs h1 {
    font-size: 2rem;
    text-align: center;
}
}