.missionSection {
    padding: 3rem 0;
}

.mission-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 3rem;
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0);
}

.mission-section .mission-section-left {
    width: 500px;
}

.mission-section .mission-section-left h1 {
    text-align: center;
    padding: 1rem;
}

.mission-section .mission-section-left p {
    text-align: justify;
    padding: 1rem;
}

.mission-section .mission-section-right {
    width: 500px;
}

.mission-section .mission-section-right img {
    width: 100%;
    border-radius: 2rem;
    padding: 1rem;
}

.wrap-reverse {
    flex-wrap: wrap-reverse;
    padding: 0;
}








@media screen and (max-width: 1200px) {

    .mission-section .mission-section-left {
        width: 1000px;
    }

    .mission-section .mission-section-left p {
        text-align: center;
    }
    
}






