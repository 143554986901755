

.component3-content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 5rem 1rem;
}

.c3-left {
    width: 50%;
    margin: auto;
}

.c3-left p {
    text-align: justify;
    padding: .5rem 0;
    color: rgb(32, 30, 30);
    font-size: 14px;
    font-weight: normal;
}

.c3-left h1 {
    font-size: 1.5rem;
    color: var(--primary-color);
    text-align: left;
    text-transform: uppercase;
    font-family: 'Bacasime Antique', serif;
}

.c3-left h2 {
    text-align: left;
    padding: .5rem 0;
    color: rgb(32, 30, 30);
    font-size: 14px;
    font-weight: normal;
}

.c3-left h3 {
    font-size: 1rem;
    color: rgb(198, 134, 24);
    letter-spacing: 3px;
    padding-top: .5rem;
    text-align: left;
}

.c3-right {
    width: 50%;
    margin: auto;
}

.c3-right-img {
    width: 100%;
    margin: auto;
}

.c3-right-img img {
    width: 100%;
    border-radius: 5px;
}



@media  screen and (max-width:940px) {

    .component3-content {
        flex-wrap: wrap;
    }
    
    .c3-left {
        width: 100%;
    }
    
    .c3-right {
        width: 100%;
    }

    .c3-left h1 {
        text-align: center;
    }

    .c3-left h2 {
        text-align: center;
    }

    .c3-left h5 {
        text-align: center;
    }



}


