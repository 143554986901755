
.section3 .menu-programs-header {
    padding: 1rem;
}

.section3 {
    background-color: rgb(4, 52, 80);  
    padding: 3rem 0;
}

.section3-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .5rem;
    padding: 1rem;
}

.section3-flexItem {
    width: 300px;
    height: auto;
}

.section3-flexItem img {
    width: 100%;
    border-radius: .2rem;
}


.section3-flexItem h1 {
    color: white;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    padding: .5rem 0;
}

.section3-flexItem {
    text-align: center;
}


.section3-flexItem button:hover {
    background-color: var(--secondary-color);
    border: none;
}


@media  screen and (max-width: 700px) {
    
    .section3-flexItem {
        width: 100%;
    }

    
}



