@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.InsuranceSection {
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(29, 28, 29, 0);
  }
  
  .InsuranceSection:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg1.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    opacity: .06;
  }



.InsuranceSection .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    padding: 5rem 0;
    margin: 1rem 0;
    margin: auto;

}

.InsuranceSection-container {
    text-align: center;
    padding: 25px;
}

.InsuranceSection-container p {
        padding: 1rem 0;
        font-family: 'Montserrat', sans-serif;
    }

.InsuranceSection h1 {
    padding: 1rem;
    font-family: 'Bacasime Antique', serif;
    font-size: 2rem;
}

.insurance-thumbnail {
    width: 120px;
}

.insurance-thumbnail img {
    width: 100%;
}

.InsuranceSection .flex-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.insuranceSection-btn button {
    margin: 1rem auto;
}


@media  screen and (max-width:940px) {
    .InsuranceSection-container h1 {
        font-size: 1.5rem;
        padding: 1rem 0;
    }


    .InsuranceSection-container p {
        font-size: 14px;
    }

    .team img {
        width: 350px;
    }

    .insurance-thumbnail {
        width: 100px;
    }

}






