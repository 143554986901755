@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

.hero {
    height:85vh;
    width: 100%;
    background-color: rgba(1, 5, 24, 0.486);
    z-index: 5555;
}

video {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;

  }

.hero .hero-container {
    width: max-content;
    bottom: 10%;
    left: 5%;
    position: absolute;
    text-align: center;
    padding: 1rem;
}

.hero-logo {
    text-align: center;
}

.hero h1 {

    font-size: 4rem;
    text-align: center;
    color: white;
    font-family: 'Libre Baskerville', serif;
    font-weight: normal;
}

.hero h2 {
    font-size: 1.5rem;
    color: rgb(229, 231, 229);
    padding: .5rem 0;
    font-weight: normal;
}

.hero h3 {
  font-size: 1rem;
  color: rgb(229, 231, 229);
  padding: .5rem 0;
  font-weight: normal;
}

.hero-container p {
    font-size: 18px;
    color: white;
}

.hero .hero-btn button {
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    color: rgb(32, 34, 34);
    border: none;
}


@media screen and (max-width:940px) {
  .hero .hero-container {
    width: 100%;
    left: 0%;
}
}



/* MEDIA QUERIES */

@media screen and (max-width:700px) {

    .hero h2 {
        font-size: 18px;
    }

    .hero h1 {
    font-size: 3rem;
    }

    .hero p {
        font-size: 12px;
    }

    .hero h3 {
        font-size: 1rem;
    }

}
