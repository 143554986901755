@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.TherapySA {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    /* background: #1f1e1ec4; */

}
  
  .TherapySA:before {
    content: '';
    position: absolute;
    /* background: url('../../assets/Section4-image1-min.jpg') no-repeat center top/cover; */
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.TherapySA .content {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    top: 10%;
    padding: 5rem 0;
}

.box-icon {
    font-size: 6rem;
    color: rgb(204, 128, 15);
    padding: 1rem 0;
}


/* LEFT */

.TherapySA .TherapySA-left {
    width: 500px;
    margin: auto;
    padding: 1rem;
}

.TherapySA .TherapySA-left  img{
    width: 100%;
    padding: 1rem;
}

.therapySA-btn {
    text-align: center;
    padding: 1rem ;
}


.therapySA-btn button span {
    color: white;
}

/* RIGHT */

.box-icon {
    width: 100%;
}

 .TherapySA-right {
    width: 60%;
    margin: auto;
    gap: 8px;
}

.therapySA-right-header h1 {
    text-align: center;
    color: rgb(27, 26, 26);
    padding: 1rem ;
    font-family: 'Bacasime Antique', serif;
    font-size: 2rem;
}

.therapySA-right-header p {
    text-align: center;
    color: rgb(34, 32, 32);
    padding: 1rem ;
}

.therapy-sa-right-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}



.TherapySA-right .box {
    height: auto;
    width: 320px;
    background-color: rgba(29, 127, 165, 0.315);
    padding: 1rem 0;
    border-radius: 1rem;
}



/* box 1 */

.box1 .box-content1 h1 {
    color: rgb(0, 0, 0);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;
    text-align: center;
    width: 100%;
    padding: 1rem;

}

.box1 .box-content1 p {
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 1rem;
    text-align: center;
}

/* box 2 */

.box2 .box-content2 h1 {
    color: rgb(0, 0, 0);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;

}

.box2 .box-content2 p {
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 1rem;
    text-align: center;
}

/* box 3 */

.box3 .box-content3 h1 {
    color: rgb(0, 0, 0);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;

}

.box3 .box-content3 p {
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 1rem;
    text-align: center;

}

/* box 4 */

.box4 .box-content4 h1 {
    color: rgb(0, 0, 0);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;
}

.box4 .box-content4 p {
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 1rem;
    text-align: center;
}


.hide-btn {
    display: none;
}



@media screen and (max-width:940px) {

    .hide {
        display: none;
    }

    .hide-btn {
        display: block;
        padding: 2rem 0;
    }


    .TherapySA .content p {
        text-align: center;
        padding: 0 1rem;
    }


    .TherapySA-right  .box{
        width: 90%;
    }
    

    .therapySA-right-header  {
        padding: 2rem 0 ;
    }

    .TherapySA .TherapySA-left {
        width: 90%;
    }
    
}



@media screen and (max-width:1200px) {   
    .TherapySA-right {
        width: 100%;
    }


    .TherapySA .content {
        flex-wrap: wrap;
        text-align: center;
        padding: 1rem;
    }
 }