
.crisis-intervention {
    width: 100%;
    height: 50vh;
    background: rgba(114, 76, 34, 0.342);
    position: relative;
  }
  
  .crisis-intervention:before {
    content: '';
    position: absolute;
    background: url('../../assets/programs-crisis-bg.webp') no-repeat center bottom/cover;
    height: 50vh;
    width: 100%;
    z-index: -1;
  }



  .crisis-intervention {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.crisis-intervention .crisis-intervention-content {
    display: block;
    position: absolute;
    top: 50%;
    padding: 1rem;
}


.crisis-intervention h1 {
    color: white;
    font-size: 3rem;
    text-align: center;
    font-weight: 300;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .scrisis-intervention .crisis-intervention-content h1 {
        font-size: 2rem;
    }
    
    }