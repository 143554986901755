@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    z-index: 10;
    background: #b4692b00;
    position: relative;
    text-align: center;
    margin-bottom: -2.4rem;
}

.navbar img {
  margin-left: 1rem;
}


.nav-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .6rem 0;
    background-color: #477fa0fd;
    border-radius: 10px;    
}


.nav-box ul {
  margin: auto 0;
}

.navbar-logo {
  z-index: 9999999;
}

.navbar .icon {
  font-size: 1.4rem;
  color: #477fa0fd;
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 1rem;
}

.navbar li {
    width: max-content;
    padding: .3rem .8rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.navbar li a {
  color: rgb(224, 226, 225);
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: normal;
}

.navbar li a:hover {
  color: rgb(230, 165, 44);
}

  .menu-item .sub__menus li a {
    color: #ffffff;
    font-size: 13px;
    padding: -1rem ;
    font-weight: 100;
  }

  .menu-item .sub__menus li a:hover {
    color: #e4a23ffd;
  }

  li .submenu-header {
    font-size: 14px;
    color: rgb(36, 131, 27);
    font-weight: bolder;
    text-decoration: underline;
    cursor: pointer;
  }


/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #477fa0fd;
    border-radius: 10px;
    width: auto;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
  }



  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 7px;
  }


.logo-hide {
  display: none;
}

@media screen and (max-width: 1200px) {

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    display: none;
  }

    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar li a {
      color: rgb(250, 250, 250);
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 70%;
        height: 100vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: rgb(255, 255, 255);
        transition: 0.5s ease-in;
        color: white;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1.5rem;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.13);
    }

    .navbar li a {
      color: black;
      font-size: 12px;
    }

    .navbar button {
        font-size: 1.8rem;
    }

  

  .logo-hide {
      display: block;
  }

  .nav-box {
    border-radius: 0;    
    background-color: #fff;
}

}


@media screen and (max-width: 700px) {
  .sub__menus ul{
    position: absolute;
    width: 100%;
    left: 0;
  }


  .navbar li {
    width: 100%;
    text-align: left;
}

.sub__menus {
  position: absolute;
  display: none;
  background: #477fa0fd;
  border-radius: 5px;
  width: 100%;
  margin-top: 6px;
  z-index: 1000;
  left: 0;
}

}